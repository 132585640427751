.Testimonial {
  .quote-icon {
    color: $text-muted;
  }

  .person {
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .name {
      a {
        @extend %anchor-styles-for-headings;
      }
    }
    .name, .designation {
      margin-bottom: 0;
    }
    .designation {
      font-size: 0.9rem;
    }
  }
}
