.Project {
  @extend %top-bottom-spacing-for-content;
  .title {
    a {
      @extend %anchor-styles-for-headings;
    }
  }

  .subtitle {
    font-weight: $font-weight-light;
    color: $text-muted;
    font-style: italic;
    margin-top: -0.375rem;
    margin-bottom: 0;
  }

  .technologies {
    .title {
      font-weight: $font-weight-normal;
      text-transform: uppercase;
    }
  }

  .content {
    .btns {
      .btn {
        margin-top: 0.7rem;
      }
    }
    @include media-breakpoint-down(sm) {
      order: 2;
      margin-top: $spacer;
    }
  }

  .image-badge-container {
    display: flex;
    flex-direction: column;

    .image-container {
      margin: 80px auto auto auto;
      
      @include media-breakpoint-down(sm) {
        margin: 40px auto;
      }

      img,
      svg {
        width: 150px;
        height: auto;
      }
    }

    .technologies {
      @include media-breakpoint-down(sm) {
        order: 2;
        margin-top: $spacer;
      }
    }
  }

  ul {
    list-style-type: none;
    li::before {
      content: '\2022';
      color: $primary;
      font-size: 1.1rem;
      font-weight: $font-weight-bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}
