// config
@import './styles/config';

// bootstrap
@import '~bootstrap/scss/bootstrap';

// custom extensions
@import './styles/extensions';

// layouts
@import './styles/layouts/MainContent';
@import './styles/layouts/Overlay';
@import './styles/layouts/Underline';
@import './styles/layouts/Badge';
@import './styles/layouts/Project';
@import './styles/layouts/ButtonLink';
@import './styles/layouts/Loading';
@import './styles/layouts/Toast';
@import './styles/layouts/PostCard';
@import './styles/layouts/SkillProgress';
@import './styles/layouts/Testimonial';

// misc
@import './styles/misc/Sidebar';
@import './styles/misc/Navbar';
@import './styles/misc/ContactForm';

// pages
@import './styles/pages/Home';
@import './styles/pages/Skills';
@import './styles/pages/NotFound';

body {
  background: url('./images/bg.jpg') center / cover no-repeat fixed;
  .body-overlay {
    position: fixed;
  }
}
strong {
  font-weight: 500;
}
