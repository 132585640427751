// custom extensions
%anchor-styles {
  text-decoration: none;
  color: inherit;
}

%anchor-styles-for-headings {
  @extend %anchor-styles;
  @include hover() {
    color: $link-hover-color;
  }
}

%top-bottom-spacing-for-content {
  // equivalent to py-4
  padding-top: $spacer * 1.5;
  padding-bottom: $spacer * 1.5;
}

%perfect-centering-for-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 6rem);
  @include media-breakpoint-down(sm) {
    height: calc(100vh - calc(#{$sidebar-height-on-sm-devices}));
  }
}
