.SkillProgress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 15%;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 30%;
    svg.icon {
      width: 100% !important;
      height: 100%;
      color: $primary;
      fill: $primary;
      path {
        fill: $primary;
      }
    }
  }
  .skill-name {
    font-weight: $font-weight-bold;
  }
  .CircularProgressbar-path {
    stroke: $primary;
  }
}
