.Badge {
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: inset 0 0 8px darken($color: $light, $amount: 5%);
  user-select: none;
  svg {
    width: 1em;
    height: 1em;
  }
}
