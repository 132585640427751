.Home {
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem 0 1.5rem;
  color: white;
  height: calc(100vh - 6rem);
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  .headings {
    text-shadow: 2px 2px 4px rgba(#000, 0.7);
    .title {
      font-weight: 700;
      text-transform: uppercase;
    }
    .pre-title,
    .post-title {
      font-weight: $font-weight-light;
    }
    .pre-title {
      span {
        display: inline-block;
        animation: wiggle .5s ease-in-out infinite;
        font-size: 2.2rem;
      }
    }
  }

  @keyframes wiggle {
    0%, 100% {
      transform: rotate(-3deg);
    }
    50% {
      transform: rotate(3deg);
    }
  }
}
