.NotFound {
  @extend %perfect-centering-for-main-content;
  color: $text-muted;
  text-transform: uppercase;
  text-align: center;
  .title {
    display: flex;
    .plug {
      margin: auto 0.5rem;
      margin-left: 0.7rem;
      svg {
        display: block;
        fill: $text-muted;
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .text {
    font-size: 1.2rem;
  }
}
