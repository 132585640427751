// theming
$primary: #30867b;
$secondary: #4d5d64;

// custom variables
$sidebar-height-on-sm-devices: 345px;

// badge
$badge-font-size: 0.85rem;
$badge-font-weight: lighter;
$badge-padding-y: 0.4rem;
$badge-padding-x: 0.5rem;

// forms
$input-bg: #f5f6f7;
$input-border-color: $input-bg;
$input-group-addon-color: #6c757d;
$input-group-addon-bg: $input-bg;
