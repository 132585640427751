.Sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  color: $navbar-dark-color;
  background-color: rgba($primary, 0.5);
  text-transform: uppercase;
  overflow-y: scroll;
  backdrop-filter: grayscale(100%) blur(25px);
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.6);
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .img-name-wrapper {
    @extend %anchor-styles;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-container {
      flex-shrink: 0;
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .intro-content {
      letter-spacing: 1px;
      color: white;
      .name {
        font-size: 1.5rem;
        a {
          @extend %anchor-styles;
        }
      }
      .subtitle {
        font-size: 1rem;
        font-weight: $font-weight-light;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-light;
    .reach-me {
      .reach-me-text {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .social-icons {
        display: flex;
        font-size: 1.2rem;
        a:first-child {
          padding-left: 0;
        }
        a {
          @extend %anchor-styles;
          padding: 0 0.3rem;
          svg {
            display: block;
            fill: $navbar-dark-color;
          }
          @include hover() {
            color: $navbar-dark-hover-color;
            svg {
              fill: $navbar-dark-hover-color;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        order: 1;
      }
    }
    @include media-breakpoint-down(sm) {
      align-self: center;
      text-align: center;
    }
  }
  @include media-breakpoint-down(sm) {
    position: relative;
  }
}

.Sidebar::-webkit-scrollbar {
  display: none;
}
