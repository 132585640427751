.Navbar.navbar {
  padding: 0;
  ul {
    width: 100%;
    flex-direction: column;
    li {
      a {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .navigation-icon {
          opacity: 0;
          font-size: 0.7rem;
          vertical-align: -0.5px;
        }
        &.active {
          .navigation-icon {
            opacity: 1;
          }
        }
        @include hover-focus() {
          .navigation-icon {
            animation: animate-navigation-icon 0.8s linear;
            opacity: 1;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      padding-top: 1.25rem;
    }
  }
  @include media-breakpoint-down(sm) {
    display: block;
    order: 2;
  }
}

@keyframes animate-navigation-icon {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0);
  }
}
